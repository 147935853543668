.search-component {
  width: 500px;
  margin-bottom: 40px;
}

.title {
  font-family: 'Raleway';
  font-size: 25px;
  width: 100%;
  display: flex;
  margin-left: 170px;
}

.radius-for-container {
  border-radius: 16px;
}

.data-table {
  width: 90%;
  margin-left: 50px;
}

.active-status {
  fill: 'green';
  font-size: 10px;
}

.inactive-status {
  fill: 'red';
  font-size: 10px;
}
