@font-face {
  font-family: 'Bold';
  src:
    local('Oswald-Bold'),
    url('./fonts/Oswald-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ExtraLight';
  src:
    local('Oswald-ExtraLight'),
    url('./fonts/Oswald-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Light';
  src:
    local('Oswald-Light'),
    url('./fonts/Oswald-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Medium';
  src:
    local('Oswald-Medium'),
    url('./fonts/Oswald-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Regular';
  src:
    local('Oswald-Regular'),
    url('./fonts/Oswald-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SemiBold';
  src:
    local('Oswald-SemiBold'),
    url('./fonts/Oswald-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro') url('./fonts/source-sans-pro-extralight.ttf')
    format('truetype');
}

/* GLOBAL PROPERTIES */
:root {
  text-align: center;
}

body {
  overscroll-behavior-x: none;
  overflow: hidden;
  margin: 0px;
}

html {
  overscroll-behavior-x: none;
}

h2,
h3,
span,
p,
button,
li,
label,
table,
div {
  font-family: 'Light', sans-serif !important;
}

label {
  color: rgb(0, 0, 0) !important;
}

[data-amplify-authenticator] {
  background-image: url('./assets/login-background.jpg');
  background-size: cover;
}

/* SIGMA IFRAME */
.full-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

/* HEADER MENU */
.inactive-link {
  text-decoration: none;
  color: black;
}

/* APP BOX */
.app-box {
  display: flex;
  width: 100%;
  background: white;
  position: fixed;
}

.login-div {
  width: 100%;
  height: 100%;
  background-image: url('./assets/login-background.jpg');
  background-size: cover;
  position: absolute;
}

/* APP CONTAINER */
.app-container {
  padding-right: 0px !important;
  margin-left: 196px !important;
  margin-top: 30px;
  height: 100vh;
}

.active-link {
  color: #76aa35;
  background-color: #e5ecf6;
  text-decoration: none;
}

/* LANDING PAGE */
.menu-card-div {
  display: grid;
  justify-content: center;
}

.menu-card {
  transition: all 0.2s ease-in-out;
  width: 309px !important;
  height: 144px !important;
  align-items: flex-start;
  flex-direction: row;
  display: flex !important;
  border-radius: 8px !important;
  justify-content: space-between !important;
  margin-inline-start: 20px !important;
}

.menu-card:hover {
  transform: scale(1.03);
  border: 1px solid black;
}

.ant-card-cover {
  height: 140px;
}

.main-body {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

/* ADMIN FORM PAGE */
.save-button,
.login-button {
  background-color: #76aa35;
  border-color: #76aa35;
  margin-top: 30px;
  width: 150px;
  height: 45px;
}

.add-vendor-form {
  margin-top: 10px;
}

.cancel-button {
  background-color: #e5ecf6;
  border-color: #e5ecf6;
  margin-top: 30px;
  width: 150px;
  height: 45px;
}

.deactivate-button {
  background-color: #e5ecf6;
  border-color: #e5ecf6;
  margin-top: 30px;
  width: 150px;
  height: 45px;
}

.activate-button {
  background-color: #e5ecf6;
  border-color: #e5ecf6;
  margin-top: 30px;
  width: 150px;
  height: 45px;
}

.reject-button {
  width: 180px;
  height: 45px;
  background-color: #e03030;
  border-color: #e03030;
  margin-top: 30px;
}

.input-fields {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-fields {
  display: inline-flex;
  width: auto;
  justify-content: left;
}

.ant-segmented-item.ant-segmented-item-selected {
  background-color: grey;
}

.ant-input,
.ant-select-selector,
.ant-select {
  background-color: white !important;
  color: black;
  margin: '20px';
}

.ant-multi-select {
  border-radius: 16px;
  color: white;
  width: 230px !important;
}

.ant-input-email,
.ant-input-pwd,
.ant-input-group {
  width: 80% !important;
}

.table {
  border-radius: 16px;
  color: white;
}

.ant-table-cell {
  color: black;
}

.ant-select-selection-item {
  display: flex;
  align-content: flex-start;
  color: black !important;
}

.dashboard-container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.azure-logo {
  width: 30px;
  height: 30px;
  margin-right: 5%;
}

.federated-sign-in-btn {
  margin: 10px 0px 10px 0px !important;
  width: 350px !important;
  height: 50px;
  border-radius: 0px !important;
  color: white !important;
  background-color: #5c8727 !important;
}

.login-logo {
  margin-top: 50px;
  height: 100px;
  width: auto;
  margin-bottom: 10px;
}

.login-header {
  justify-content: center;
  display: flex;
  margin-left: 90px;
  margin-right: 90px;
  font-weight: 1100;
  font-family: 'Semibold', sans-serif !important;
}

/* LOGIN PAGE */
.login {
  background-color: #ffffff;
  display: inline-block;
  text-align: center;
  height: 625px;
  width: 475px;
  border-radius: 8px;
  box-shadow: -0.25px -0.25px 16px -5px;
  margin: 75px 0px 0px 0px;
}

.inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.email-header {
  margin: -10px 0px 5px 62px;
  font-family: 'Regular', sans-serif !important;
  font-weight: 900;
  align-self: flex-start;
}

.otp-header {
  font-family: 'Regular', sans-serif !important;
  font-weight: 900;
  align-self: flex-start;
}

.login-email-field {
  width: 350px;
}

.login-button,
.verify-otp-button {
  width: 350px;
  border-radius: 0px !important;
  margin-top: 25px !important;
  background-color: #5c8727 !important;
  color: white;
}

.login-div-separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.header-appbar-div {
  font-size: 21px;
  text-align: center;
}

.leftside-list {
  animation: slideInFromLeft 0.6s ease-out;
}

#impersonate {
  background-color: none;
  width: 100%;
  display: flex;
  color: #76aa35;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin-right: 20px;
  width: 238px;
  padding-left: 5px;
  padding-right: '5px';
}

.glowred {
  text-shadow:
    0 0 10px #fff,
    0 0 10px #e60073,
    0 0 10px #e60073,
    0 0 10px #e60073,
    0 0 10px #e60073;
  text-align: center;
  color: #fff;
  opacity: 0.8;
}

.glowgreen {
  text-shadow:
    0 0 10px #fff,
    0 0 10px #76aa35,
    0 0 10px #76aa35,
    0 0 10px #76aa35,
    0 0 10px #76aa35;
  text-align: center;
  color: #fff;
  opacity: 0.8;
}

.pepper::before {
  content: 'U+1F336'; /* Unicode value for pepper */
  font-size: 2rem; /* set font size */
  color: red; /* set color */
}

.separator-text {
  width: 70px;
  text-align: center;
  color: #5c6670;
}

.left-separator-line,
.right-separator-line {
  flex: 1;
  height: 1px;
  background-color: #5c6670;
}

.left-separator-line {
  margin-left: 70px;
}

.right-separator-line {
  margin-right: 70px;
}

.verify-otp-button {
  margin-top: 15px;
}

.amplify-loader.login-loader {
  margin-top: 20px;
}

.login-button:hover {
  color: white;
}

.black-box {
  text-align: left;
  position: absolute;
  left: 20%;
  top: 20%;
  transform: translate(5%, 0);
  border-style: solid;
  border-radius: 10px;
  padding-right: 80px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.black-box-sharp {
  background-color: #f7f9fb;
  margin-top: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 85%;
  border-radius: 8px;
}

.white-box-round {
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 85%;
  border-radius: 2px;
}

.black-box-sharp-double {
  text-align: center;
  border-style: solid;
  margin-top: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 45%;
}

/* DASHBOARDS */
.sigma-iframe {
  display: flex;
  height: 100%;
  width: 100%;
  border: none;
}

.popup-iframe {
  display: flex;
  height: 82%;
  width: 100%;
  border: none;
}

.popup-close {
  height: 5%;
  display: flex;
}

.popup-close {
  height: 5%;
  display: flex;
}

.custom-spin .ant-spin-dot-item {
  background-color: #cf4520;
}

.embed-container {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 25px;
}

/* POPUPS */
.embed-popup {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.embed-popup-box {
  background-color: #f1f1f1;
  width: 95%;
  height: 95%;
  padding: 5px 15px 1% 15px;
  border-radius: 12px;
  border: none;
}

/* FILTER SUMMARY */
.filter-summary-drawer {
  z-index: 1;
}

.css-q96sj7-MuiDrawer-docked {
  width: 49px !important;
}

.css-q96sj7-MuiDrawer-docked .MuiDrawer-paper {
  width: 49px !important;
}

.list-item-label {
  padding: 0px !important;
}

.filter-summary-filter-button {
  min-height: 48;
}

.filter-summary-filter-icon {
  min-width: 0 !important;
  justify-content: center !important;
}

.filter-separator {
  margin-top: 10px;
  margin-bottom: 10px;
}

#date-options-container .canned-options-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#date-options-container .canned-options-div .switch-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-items {
  border-radius: 10px !important;
  padding-left: 7px !important;
}

.filter-summary-left-div {
  width: 5px;
  background-color: #cf4520ad;
  border-radius: 10px 0 0 10px;
}

.filter-summary-cards {
  width: 160px;
  white-space: normal;
}

.filter-summary-card-headers {
  display: flex;
  text-emphasis: bold;
  font-size: x-small;
}

.filter-summary-card-content {
  padding-left: 20px;
  font-size: x-small;
}

.filter-summary-letters {
  font-size: 1.1rem !important;
  font-weight: bold !important;
}

/* CUSTOM ACCORDION */
.base-accordion {
  width: 100%;
  margin-bottom: 0px;
  margin-top: 0px;
}

.accordion-div {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
}

.amplify-flex.amplify-alert {
  margin: 20px;
}

.filter-load-save-div {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  margin-top: 15px;
  height: 32px;
  align-items: center;
}
.filter-import-export {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  margin-top: 15px;
  height: 32px;
  align-items: center;
}
.filter-import-export > h2 {
  margin-right: 10px;
}
.filter-preset-header-div {
  display: flex;
}
.load-filters-select,
.save-filters-input {
  width: 18%;
  height: 32px;
  font-family: 'Light', sans-serif !important;
  margin-left: 20px;
  text-align: left;
  background-color: white;
  border-radius: 0px !important;
}

#broker .input {
  text-align: left;
  width: 100%;
  background-color: white;
  border-radius: 0px !important;
}

.opacity-enabled .MuiSelect-nativeInput {
  opacity: 0.4;
  height: 32px;
}

.opacity-disabled .MuiSelect-nativeInput {
  opacity: 0;
  height: 32px;
}
.save-filters-icon-button,
.reset-icon-button,
.delete-icon-button {
  border-radius: 0px !important;
  color: white !important;
}
.save-filters-icon-button,
.reset-icon-button,
.export-filters-icon-button {
  background-color: #5c8727 !important;
}
.delete-icon-button {
  background-color: #cf4620 !important;
}

.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #5c8727;
}

.ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}

.ant-spin .ant-spin-dot-item {
  background-color: #cf4520 !important;
}

.ant-select-multiple .ant-select-selection-overflow {
  position: absolute;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.ant-select-selection-search {
  width: 100% !important;
  height: 100% !important;
}

/* CONTROL CARD */
.control-card {
  width: 25%;
  background: white;
  border: solid 1px;
}
.control-card:nth-child(3n + 1) {
  width: 25%;
  background: white;
  border: solid 1px;
  border-right: 0px;
  border-radius: 5px 0px 0px 5px;
}
.control-card:nth-child(3n) {
  width: 25%;
  background: white;
  border: solid 1px;
  border-left: 0px;
  border-radius: 0px 5px 5px 0px;
}
.right-radius {
  width: 25%;
  background: white;
  border: solid 1px;
  border-radius: 0px 5px 5px 0px;
}
.total-radius {
  width: 25%;
  background: white;
  border: solid 1px !important;
  border-radius: 5px 5px 5px 5px !important;
}
.control-title {
  width: 100%;
  background: #2e4313;
  border-bottom: solid 1px;
  color: white;
}
.control-card-items {
  width: 100%;
  list-style-type: '-';
  margin: 0;
  padding: 5% 10% 20% 10%;
}

/* filter confirm page */
.full-control-container {
  display: flex;
  align-items: center;
}
.filter-container-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.control-filter-list {
  padding: 2%;
  row-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  width: 88%;
}
.control-page-title {
  color: #2e4313;
  width: 10%;
  overflow-wrap: break-word;
  padding: 1%;
}
.control-page-triangle {
  border-top: 2rem solid transparent;
  border-bottom: 2rem solid transparent;
  border-left: 1rem solid #2e4313;
  width: 1%;
}
.filters-page-title {
  color: #2e4313;
}
.filter-divider {
  border-top: solid 1px;
  border-color: gray;
}
.control-filter-list > div {
  flex-basis: calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
}
.filters-container {
  width: 100%;
}
.shared-profiles-popup {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.shared-profiles-popup-box {
  background-color: #f1f1f1;
  width: 50%;
  height: 30%;
  padding: 5px 15px 1% 15px;
  border-radius: 12px;
  border: none;
  display: flex;
  flex-direction: column;
}

.shared-profiles-popup-close {
  display: flex;
}

.shared-profiles--profile-selection {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.shared-profiles--profile-selection > div {
  display: flex;
  flex: 1;
  padding: 0 15px;
  flex-direction: column;
}

.shared-profiles-label-container {
  text-align: left;
}

.shared-profiles-import-input,
.shared-profiles-export-input {
  display: flex;
}

.shared-profiles-import-input > input,
.shared-profiles-export-input > div {
  flex-grow: 1;
  margin-left: 0;
}

.copy-message {
  color: #5c8727;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.filters-popup {
  background-color: #f1f1f1;
  display: flex;
  height: 80%;
  border-radius: 10px;
  border-color: rgb(200, 200, 200);
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  overflow-y: auto;
}
.filters-popup::-webkit-scrollbar {
  width: 8px;
}

.filters-popup::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.filters-popup::-webkit-scrollbar-track {
  background-color: transparent;
}

.popup-content {
  display: flex;
  flex-direction: column;
  background: white;
  height: 80%;
  width: 70%;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 99999;
}
.popup-title {
  color: #2e4313;
}
.filters-button-container {
  padding-top: 2%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.filters-button-confirm {
  background: #5c8727;
  border: none;
  color: white;
  padding: 1%;
}
.filters-button-cancel {
  background: #cf4620;
  border: none;
  color: white;
  padding: 1%;
}
.period-switch-div {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.prev-div {
  display: flex;
}
.match-switch-div {
  display: flex;
  align-items: center;
  padding-top: 7%;
}

.timeframes-div {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.period-switch-div .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #7ebf2e !important;
}

.period-switch-div .MuiSwitch-track {
  background-color: #717073 !important;
}

.period-switch-div .Mui-disabled + .MuiSwitch-thumb {
  background-color: #717073 !important;
}

.period-switch-div .MuiSwitch-thumb {
  background-color: #5c8727 !important;
}

.period-switch-div .Mui-disabled .MuiSwitch-thumb {
  background-color: #d5d5d5 !important;
}
.match-button-div {
  display: flex;
  padding-top: 35px;
  margin-right: 20px;
}

.match-button-div .MuiButton-outlined {
  background-color: #f1f1f1 !important;
  border-color: #d5d5d5 !important;
  color: #171717 !important;
}

.match-button-div .Mui-disabled {
  background-color: #d2cfcf !important;
  border-color: #d5d5d5 !important;
}

.top-iframe-div {
  display: flex;
  justify-content: flex-end;
  padding: 0px 12px 12px 0px;
}

.toggle-btn-div {
  align-self: center;
}

.toggle-title {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 11px;
}

.toggle-btn-group {
  display: block;
  align-self: center;
}

.canned-dates-alone {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: center;
  margin-top: 1%;
}

.date-submit-button {
  margin-top: 10px !important;
}

.curr-prev {
  margin-top: 4px !important;
  margin-right: 20px;
}

.acc-titles {
  text-align: start;
  font-weight: bold;
  font-size: 15px;
  font-family: 'Medium' !important;
}

.toggle-button-date .MuiToggleButton-root.Mui-selected {
  background-color: #9f9f9f !important;
  color: #ffffff !important;
  border-left-color: #5c8727 !important;
  border-left-width: 4px !important;
  border-radius: 2px !important;
  height: 36.5px;
  font-size: 13px;
  padding: 4px 11px 4px 11px;
}

.toggle-button-date .MuiToggleButton-root {
  background-color: #f1f1f1 !important;
  border-color: #d5d5d5 !important;
  border-radius: 2px !important;
  height: 36.5px;
  font-weight: bold !important;
  font-size: 13px;
  padding: 4px 11px 4px 11px;
}

.toggle-button-mode .MuiToggleButton-root.Mui-selected {
  background-color: #5c8727 !important;
  color: #ffffff !important;
  border-radius: 2px !important;
  height: 36.5px;
  font-family: 'Medium', sans-serif !important;
}

.toggle-button-mode .MuiToggleButton-root {
  background-color: #f1f1f1 !important;
  border-color: #d5d5d5 !important;
  border-radius: 2px !important;
  height: 36.5px;
  font-weight: bold !important;
  font-family: 'Medium', sans-serif !important;
}

.active-status {
  fill: 'green';
  font-size: 10px;
}

.inactive-status {
  fill: 'red';
  font-size: 10px;
}

.ant-picker:hover {
  border-color: #5c8727 !important;
}

.ant-picker-focused {
  border-color: #5c8727 !important;
  box-shadow: 0 0 0 2px #5c872765 !important;
}

.ant-picker-active-bar {
  background: #5c8727 !important;
}

.ant-picker-week-panel-row-selected td {
  background: #70a42f !important;
}

/* Highlights start week and end week */
.ant-picker-dropdown
  .ant-picker-week-panel-row-range-start
  td.ant-picker-cell:before,
.ant-picker-dropdown
  .ant-picker-week-panel-row-range-end
  td.ant-picker-cell:before {
  background: #70a42f;
}
/* Highlights selected weeks between start week and end week */
.ant-picker-dropdown .ant-picker-week-panel-row-range-hover td:before {
  background: #c5dfa5;
}

.ant-picker-header-view button:hover {
  color: #70a42f !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #70a42f !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #70a42f !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #70a42f !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background-color: #70a42f70 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before {
  background-color: #70a42f70 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #70a42f70 !important;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-in-form-item,
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-in-form-item:hover {
  background-color: #5c8727;
  border-color: #5c8727;
  color: white !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: #cf4520;
}

.ant-picker {
  border-color: #d5d5d5 !important;
  border-radius: 2px !important;
  height: 36.5px !important;
  background-color: #f1f1f1 !important;
}

/* FOOTER */
.copy-right {
  font-size: 0.5em;
  margin-top: 40px;
  margin-right: 20px;
}

.menu-title {
  display: flex;
  margin-left: 20px;
}

/* USF brandline colors */
.usf-green {
  color: #5c8727 !important;
}

.usf-orange {
  color: #cf4520 !important;
}

.usf-grey {
  color: #717073 !important;
}

/* Checkbox */
.custom-checkbox .amplify-checkbox__icon {
  background-color: #5c8727;
}

/* Service Level Information Buttons */

.information-buttons {
  background-color: #f1f1f1 !important;
  color: #4f4f4f !important;
  border-radius: 2px !important;
  font-weight: bold !important;
  border-left: 3px solid #9dc26f !important;
  margin-right: 10px !important;
  font-family: 'Regular', sans-serif !important;
}

/* Back button */
.back-button:hover {
  cursor: pointer;
}

.edit-user-inputs {
  width: 80% !important;
}

.impersonate-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 115%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  justify-content: center;
}

.impersonate-inner {
  position: relative;
  padding: 40px 0px;
  width: 100%;
  max-width: 480px;
  height: 45%;
  inset: 150px;
  margin-left: 20px;
  background-color: #ffffff;
  border-radius: 5px;
}

.impersonate-inner .close-button {
  position: absolute;
  top: 16px;
  right: 16px;
}

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 100px;
}

.inner-container .vendor-selection {
  text-align: center;
  margin: 10px 0px;
  padding: 10px 10px;
}

.vendor-selection {
  height: 50%;
}

.dynamic-form-cards {
  display: grid;
  padding: 5px;
  background-color: #f4f4f4;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 5px;
  flex-direction: 'row';
  overflow-y: scroll;
  height: 470px;
}

.add-edit-broker-form {
  margin: 10px;
}

.form-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.MuiContainer-root {
  overflow: hidden;
}

.center-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  margin: auto;
  width: 50%;
  height: 100%;
}

.appbar-div-grey {
  color: grey;
  font-weight: bold;
  margin-right: 5px;
}

.appbar-div-white {
  color: white;
  font-weight: bold;
}

.appbar-menu-div {
  display: flex;
  align-items: flex-start;
  background-color: #5c8727;
  height: 100%;
  width: 10%;
  border-radius: 0px;
}

.appbar-button-menu {
  display: flex;
  justify-content: center;
  width: 100%;
}

.appbar-impr-name-div {
  color: #ffffff;
  font-size: small;
  font-weight: bold;
}

/* SIDE MENU use of important due the need to override the element style*/
.current-page {
  padding: 0 !important;
  background-color: #5c87272e !important;
  border-left: solid 7px #5c8727 !important;
}

.group-selected {
  padding: 0 !important;
  background-color: #ffffff !important;
  border-left: solid 7px #5c8727 !important;
}

.inactive-page {
  padding: 0px 0px 0px 7px !important;
}

.group-father-active {
  padding: 0 !important;
  margin-top: 0px !important;
  height: 46px !important;
  background-color: #ffffff !important;
  border-left: solid 7px #5c8727 !important;
}

.group-father-inactive {
  margin-top: 0px !important;
  height: 46px !important;
  padding: 0px 0px 0px 7px !important;
}

.menu-item {
  min-width: 35px !important;
  margin-left: 16px !important;
}

.submenu-item {
  min-width: 35px !important;
  margin-left: 24px !important;
}

.dq-text {
  display: contents;
}

.primary-text .MuiListItemText-primary {
  font-size: 14px;
  line-height: 1.53846154em;
  padding: 0.41666667rem 0 0.41666667rem 0.41666667rem;
  color: #4f4f4f;
  font-weight: bolder !important;
}

.secondary-text .MuiListItemText-secondary {
  font-size: 13px;
  line-height: 1.6667em;
  padding: 0.41666667rem 0 0.41666667rem 0.41666667rem;
  color: #4f4f4f;
  font-weight: bolder !important;
}

.menu-list .MuiListItemButton-root {
  border-bottom: 1px solid #d7d7d7;
}

.list {
  padding: 0px !important;
}

.bold-font {
  font-weight: bold;
  margin-left: 2%;
}
/* TRAINING PAGE*/
.training-page {
  height: 100%;
}
.current-training {
  background-color: #f1f1f1;
  height: 90%;
}
.training-options {
  height: 10%;
  display: flex;
  padding-left: 3%;
  align-items: flex-end;
}
.training-tab {
  height: 50%;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  user-select: none;
}
.active-tab {
  height: 50%;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: #f1f1f1;
  border-left: 5px solid #5c8727;
  user-select: none;
}
.content {
  display: none;
}
.active-content {
  height: 100%;
  display: flex;
}

.videos-container {
  width: 100%;
  padding-top: 2%;
  display: flex;
  justify-content: space-evenly;
}
.video-player-container {
  width: 65%;
}
.video-player {
  background-color: white;
  padding: 2%;
  border: solid 1px;
}
.player-contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.video-title {
  text-decoration: underline;
}
.video-list {
  background-color: white;
  overflow-y: scroll;
  width: 30%;
  height: 95%;
  border: solid 1px;
}
.video-list::-webkit-scrollbar {
  width: 0;
}
.selected-cat-tab {
  background-color: #5c87272e !important;
}

.pdf-player {
  height: 95%;
  background-color: white;
  padding: 2%;
  border: solid 1px;
}
.pdf-player-contents {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.summary-container {
  padding: 2%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.content-container {
  overflow: scroll;
  width: 95%;
  background-color: white;
}
.content-container::-webkit-scrollbar {
  width: 0;
}
.summary-text {
  padding: 2%;
}
.text-box-1 {
  display: flex;
  justify-content: space-around;
  font-size: large;
}
.text-box-2 {
  display: flex;
  padding-top: 4%;
}
.tutorial-text-1 {
  text-align: justify;
  width: 65%;
}

.collaboration-img {
  font-size: small;
  display: flex;
  flex-direction: column;
}

.view-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tutorial-text-2 {
  text-align: justify;
  width: 60%;
  padding-top: 1%;
}
.tutorial-text-3 {
  text-align: center;
  width: 60%;
  padding-top: 1%;
  font-weight: bold;
}
/* FAQ PAGE */
.faq-div {
  padding: 10px 10px 25px 10px;
  overflow: scroll;
  height: 100%;
}

.faq-header-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.faq-accordion-div {
  border: 16px;
  padding: 5px 0px;
}

.faq-accordion {
  border-radius: 16px !important;
}

.Mui-expanded:last-of-type {
  border-width: 1px;
  border-color: #f1f1f1;
  border-style: solid;
  background-color: #f1f1f1;
}

[data-testid='ExpandCircleDownIcon'],
[data-testid='ExpandMoreIcon'] {
  fill: #5c8727 !important;
}

.accordion-text {
  justify-content: left;
  text-align: justify;
}
.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.app-bar-logo {
  display: flex;
  width: 150px;
  margin-left: 20px;
}

/*Content*/
.amplify-flex {
  gap: 0 !important;
}
.pepper-home {
  height: 45px;
  width: 45px;
}

.yearly-style {
  background-color: #f1f1f1;
}

.filters-button {
  background-color: #f1f1f1 !important;
  border-radius: 2px !important;
  border-color: #d5d5d5 !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  height: 36.5px;
  font-family: 'Regular', sans-serif !important;
  color: #717073 !important;
  font-weight: bold !important;
  min-width: 7.5rem !important;
  max-width: 7.5rem !important;
}
.filters-button.Mui-disabled {
  min-width: 7.5rem !important;
}
.filters-button .MuiButton-startIcon {
  margin: 0 !important;
}
.MuiCircularProgress-circle {
  color: #717073 !important;
}

.center-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.input-vendor-group-name,
.select-vendors {
  margin: 0;
  padding: 0;
}

.input-vendor-group-number,
.select-vendors {
  margin: 0;
  padding: 0;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-overflow {
  flex-wrap: nowrap;
}

.select-vendors {
  padding: 0 !important;
  margin: 0 !important;
}

.select-vendors-popup {
  text-align: left;
}

#create-user {
  text-align: left;
}

#create-user .cancel-button {
  margin-right: 8px;
  margin-left: 16px;
  border-color: #5c8727;
}

#broker .save-button {
  margin-bottom: 10px;
}

/* Overwrite the global selector */
#create-user .cancel-button *,
#create-vendor .cancel-button *,
#edit-user .cancel-button *,
#edit-vendor .cancel-button * {
  color: #5c8727;
}

#edit-user .deactivate-button * {
  color: red;
}

#edit-user .activate-button * {
  color: green;
}

#create-user .save-button *,
#create-vendor .save-button *,
#edit-user .save-button *,
#edit-vendor .save-button * {
  margin-left: 8px;
  background-color: #5c8727;
}

/* Overwrite the global selector */
#create-user .save-button *,
#create-vendor .save-button *,
#edit-user .save-button *,
#edit-vendor .save-button * {
  color: white;
}

#create-vendor,
#edit-vendor {
  margin: 30px;
}
#edit-user,
#create-user {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 2rem;
}
#create-user .ant-row {
  display: contents;
  padding: 0px;
  margin: 0px;
  padding-right: 0px;
}
#create-user .ant-form-item {
  width: 160%;
  margin-left: 10px;
}
#edit-user .ant-form-item {
  width: 93%;
  margin-left: 10px;
}
#edit-vendor .attribute-box-headers {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates 3 equal-width columns */
  grid-gap: 10px;
}
#edit-vendor .attribute-box-fields {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 equal-width columns */
  grid-gap: 10px;
}
.attribute-box {
  background-color: #f2f2f2;
  margin-top: 10px;
  padding: 20px;
  width: 100%;
  border-radius: 4px;
}
#edit-user .ant-form,
#create-user .ant-form {
  width: 40%;
}
#edit-user label,
#edit-user label.ant-form-item-required,
#create-user label,
#create-user label.ant-form-item-required,
#create-vendor label,
#create-vendor label.ant-form-item-required,
#edit-vendor label,
#edit-vendor label.ant-form-item-required {
  font-weight: bold;
  font-size: 1rem;
}
#edit-user .ant-input,
#edit-user .ant-select-selector,
#create-user .ant-input,
#create-user .ant-select-selector,
#edit-vendor .ant-input,
#edit-vendor .ant-select-selector,
#create-vendor .ant-input,
#create-vendor .ant-select-selector {
  font-family: light;
  font-size: 1rem;
  border-radius: 1px;
  border: none;
}

#edit-vendor .page-title,
#create-vendor .page-title,
#create-user .page-title,
#edit-user .page-title,
#user-list-body .manage-users-title,
#vendor-list-body .manage-vendors-title {
  font-family: 'Bold', sans-serif !important;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
}
#edit-user .edit-user-buttons,
#edit-vendor .edit-vendor-buttons {
  display: flex;
}
/* #edit-user-buttons {
  .ant-row ant-form-item-row css-dev-only-do-not-override-fpg3f5{
    margin: 0px;
    padding: 0px;
  }
} */
.edit-vendor-spinner {
  margin-top: 10px;
}
#create-user .create-user-buttons {
  display: flex;
  flex-direction: flex-start;
}
.button-items {
  display: flex;
  justify-content: space-around;
}
#edit-user .cancel-button,
#edit-user .save-button,
#create-user .cancel-button,
#create-user .save-button,
#edit-vendor .cancel-button,
#edit-vendor .save-button,
#create-vendor .cancel-button,
#create-vendor .save-button,
#edit-user .deactivate-button,
#edit-user .activate-button #broker .save-button {
  background-color: #5c8727;
  width: 100px;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 8px;
  border: none;
  border-radius: 1px;
  text-transform: uppercase;
}

#edit-user .cancel-button,
#create-user .cancel-button,
#edit-vendor .cancel-button,
#create-vendor .cancel-button {
  background-color: #f2f2f2;
}
#edit-user .deactivate-button {
  background-color: #f2f2f2;
}

#edit-user .activate-button {
  background-color: #f2f2f2;
}

#edit-user .save-button,
#create-user .save-button,
#edit-vendor .save-button,
#create-vendor .save-button,
#broker .save-button {
  color: white;
}

#create-vendor .save-button {
  height: inherit;
}

#edit-user .save-icon,
#edit-vendor .save-icon,
#create-user .save-icon,
#create-vendor .save-icon {
  fill: 'white';
}
#edit-user .cancel-icon,
#edit-vendor .cancel-icon,
#create-user .cancel-icon,
#create-vendor .cancel-icon {
  fill: 'black';
}

#edit-user .deactivate-icon {
  fill: 'red';
}

#profile-page {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 2rem;
}

#profile-page .attribute-box {
  background-color: #f2f2f2;
  margin-top: 10px;
  padding: 20px;
  width: 100%;
  border-radius: 4px;
}
#profile-page .ant-form {
  width: 40%;
}
#profile-page label,
label.ant-form-item-required {
  font-weight: bold;
  font-size: 1rem;
}
#profile-page .ant-input,
#profile-page .ant-select-selector {
  font-family: light;
  font-size: 1rem;
  border-radius: 1px;
  border: none;
}

#profile-page .page-title {
  font-family: 'Bold', sans-serif !important;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
}
#profile-page .profile-email-message {
  font-weight: lighter;
  margin: 0;
  color: #4f4f4f;
}
#profile-page .profile-buttons {
  display: flex;
}
#profile-page .button-items {
  display: flex;
  justify-content: space-around;
}
#profile-page .save-button {
  background-color: #5c8727;
  width: 100px;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 8px;
  border: none;
  border-radius: 1px;
  text-transform: uppercase;
}

#profile-page .save-button * {
  color: white;
}
#profile-page .save-icon {
  fill: 'white';
}
#user-list-body {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 50px;
}

#vendor-list-body {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 50px;
}

#user-list-body .manage-control {
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 90%;
}
#user-list-body .alert-banner {
  width: 100%;
}
#vendor-list-body .manage-control {
  display: flex;
  justify-content: left;
  margin-left: 48px;
}
#vendor-list-body .search-component,
#user-list-body .search-component {
  width: 100%;
  display: flex;
  margin: 10px 0px 30px 0px;
  justify-content: center;
}
#vendor-list-body .data-table {
  width: 90%;
}
#user-list-body .users-table {
  width: 90%;
}

.center-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.amplify-tabs {
  display: none !important;
}

.contact-input {
  width: 80%;
}

.custom-icon-button {
  border-radius: 4px !important;
}

/*Loading*/

.loader {
  width: 25px;
  height: 25px;
  border: 3px solid #171717;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 12px;
  animation: App-logo-spin 1s linear infinite;
}

.loading-title {
  font-family: 'Source Sans Pro', sans-serif;
  color: #171717;
  font-weight: 350;
  font-size: 23px;
}

.loading-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 45%;
  left: 5%;
}

/* Form Item simulation */
.form-item-custom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 2%;
}

.form-label-custom {
  display: flex;
  justify-content: flex-end;
  width: 16%;
}

.form-select-dropdown-custom {
  width: 58%;
  margin-left: 9px;
  flex-wrap: nowrap;
}

/* Data Query PAGE */
.dq-toggle-button {
  height: 36.5px;
}

.app-box {
  animation: fadein 0.8s;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* MEDIA QUERIES */
@media screen and (max-width: 885px) {
  .nav-title {
    display: none;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
