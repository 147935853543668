.refresh-button {
  width: 100%;
  height: 36.5px;
  background-color: #e46f4e !important;
  text-transform: none !important;
  border-radius: 2px !important;
}

.icon-container {
  display: contents;
  color: white;
}

.cached-outlined-icon {
  fill: white !important;
  width: 20px !important;
  background-color: #e46f4e;
}

.refresh-icon-container {
  height: 100%;
  display: flex;
  align-items: center;
}
