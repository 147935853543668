.main-list {
  padding: 0px;
}

.main-itemButton-list {
  padding: 0px;
}

.main-list-date {
  font-size: x-small;
}
